import { Fragment } from 'react';

import LeadSlide from './content-blocks/lead-slide';
import What from './content-blocks/what';
import Integrations from './content-blocks/integrations';
import Pricing from './content-blocks/pricing';
import Footer from './common/footer';
import SignUpContainer from '../containers/common/sign-up-container';

const Index = (props) => {
    return (
        <Fragment>
            <LeadSlide toggleSignUp={ props.toggleSignUp } scrollTo={ props.scrollTo } headerOpacity={props.headerOpacity} />
            <What />
            <Integrations toggleSignUp={props.toggleSignUp} />
            <Pricing toggleSignUp={ props.toggleSignUp } scrollTo={ props.scrollTo } />
            <Footer toggleSignUp={props.toggleSignUp} />
            { props.signUpOpen && 
                <SignUpContainer toggleSignUp={ props.toggleSignUp } /> 
            }
        </Fragment>
    );
}

export default Index;
