import Logo from './../../assets/img/expensr-logo.webp';

const Header = (props) => {
    return (
        <header> 
            <div className="content">
                <div className="width-3 logo">
                    <img src={ Logo } alt="Expensr Logo" />
                </div>

                <nav className="width-9 menu">
                    <ul>
                        <li>
                            <a href="#what" onClick={(e) => props.scrollTo(e, "what")}>What?</a>
                        </li>
                        <li>
                            <a href="#integrations" onClick={(e) => props.scrollTo(e, "integrations")}>Works with...</a>
                        </li>
                        <li>
                            <a href="#pricing" onClick={ (e) => props.scrollTo(e, "pricing") }>Pricing</a>
                        </li>

                        <li>
                            <a href="#start-now" className="btn primary" onClick={ (e) => props.toggleSignUp(e) }>Start free</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div id="headBg" style={{ opacity: props.headerOpacity }}></div>
        </header>
    );
}

export default Header;
