import EmailInvoice from './../../assets/img/email-invoice.webp';
import PhotoReceipt from './../../assets/img/photo-receipt.webp';
import FormattedIcon from './../../assets/img/formatted-icon.webp';
import ExBox from './../../assets/img/ex-box.webp';

const What = () => {
    return (
        <section id="what" className="white what">

            <div className="content">
                <div className="width-8 centred">
                    <h2>What is Expensr?</h2>
                    <p>No-one likes processing their expenses. It&rsquo;s tedious, mind-numbing work - and it takes up valuable time and resource that could be used more productively.</p>
                    <p>Expensr is an AI-powered tool that processes your team&rsquo;s expenses for them, saving you time and money. Simply photograph receipts or forward emailed invoices to your Expensr inbox, then let our AI-powered tools do the work for you.</p>
                </div>
                
                <div className="width-12 diagram">

                    <div className="step one">
                        <div>
                            <div className="circled">
                                <img src={ EmailInvoice } alt="Email invoice icon" />
                            </div>
                            <h6>Forward emailed invoices</h6>
                        </div>

                        <div>
                            <div className="circled">
                                <img src={PhotoReceipt} alt="Photograph receipts icon" />
                            </div>
                            <h6>Photograph physical receipts</h6>
                        </div>
                    </div>
                    
                    <div className="step two">
                        <div className="circled">
                            <img src={ExBox} alt="Expensr ex-box icon" />
                        </div>
                        <h6>Our &quot;Ex-Box&quot; does its magic. Working out all the details of the expense.</h6>
                    </div>
                    
                    <div className="step three">
                        <div className="circled">
                            <img src={FormattedIcon} alt="Formatted and processed expesnses icon" />
                        </div>
                        <h6>Your expenses are added wherever you need them, in the format you need them.</h6>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default What;
