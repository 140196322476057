import Error from './error';
import ValidationError from './validation-error';

const Contact = (props) => {

    return (
        <div className="width-4 contact" id="contact">
            <h2>Questions? Drop us a line...</h2>

            <Error error={props.error} />

            <form>
                <div className="input text">
                    <label htmlFor="name">Your name</label>
                    <input type="text" id="name" name="name" placeholder="Your name" onChange={(e) => props.updateField(e)} value={props.formData.name} />
                    <ValidationError valErrs={props.valErrs} fieldName="Name" />
                </div>

                <div className="input text email">
                    <label htmlFor="email">Your email address</label>
                    <input type="email" id="email" name="email" placeholder="Your email address" onChange={(e) => props.updateField(e)} value={props.formData.email} />
                    <ValidationError valErrs={props.valErrs} fieldName="Email" />
                </div>

                <div className="input text blind">
                    <label htmlFor="phone" className="display">Phone number</label>
                    <input type="text" id="phone" name="phone" placeholder="Phone number" onChange={(e) => props.updateField(e)} value={props.formData.phone} />
                    <ValidationError valErrs={props.valErrs} fieldName="Phone" />
                </div>

                <div className="input textarea">
                    <label htmlFor="message">Your message</label>
                    <textarea id="message" name="message" placeholder="Message" onChange={(e) => props.updateField(e)} value={props.formData.message}></textarea>
                    <ValidationError valErrs={props.valErrs} fieldName="Message" />
                </div>

                <button className="btn primary" onClick={ (e) => props.submitForm(e) }>{props.btnTxt}</button>
            </form>
        </div>
    );

}

export default Contact;