import { useState, useEffect } from "react";
import Index from "../components";


const IndexContainer = () => {

    const [signUpOpen, setSignUpOpen] = useState(false);
    const [headerOpacity, setHeaderOpacity] = useState(0);

    // Handle the toggling of the sign up modal 
    const toggleSignUp = (e) => {
        e.preventDefault();
        setSignUpOpen(!signUpOpen);
    }

    // Scroll the page to a given ID 
    const scrollTo = (e, id) => {
        e.preventDefault();
        const element = document.getElementById(id);
        // Get the offset of the element
        let offset = element.offsetTop;
        offset = offset - 126;
        if (id === "integrations") offset += 0.5;
        // Scroll the page to the element
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });
    }

    // Calculate the opacity of the header
    const calcHeaderOpacity = () => {
        // Calculate the height of the visible window
        const windowHeight = window.innerHeight;
        // Calculate the scroll position of the window
        const scrollPos = window.pageYOffset;
        // Calculate the desired opacity
        const opacity = (scrollPos < windowHeight) ? scrollPos / windowHeight : 1;
        setHeaderOpacity(opacity);
    }

    // Calculate the opacity of the header on scroll
    const addOpacityListenner = () => {
        window.addEventListener('scroll', calcHeaderOpacity);
    }

    // Calculate the initial opacity of the header on page load
    /* eslint-disable */
    useEffect(() => {
        calcHeaderOpacity();
        addOpacityListenner();
    }, []);
    /* eslint-enable */

    return (
        <Index
            signUpOpen={signUpOpen}
            toggleSignUp={toggleSignUp}
            scrollTo={scrollTo}
            headerOpacity={headerOpacity}
        />
    );
}

export default IndexContainer;