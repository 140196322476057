import ContactContainer from '../../containers/common/contact-container';
import Logo from './../../assets/img/expensr-logo.webp';
import Facebook from './../../assets/img/facebook.webp';
import LinkedIn from './../../assets/img/linkedin.webp';

const Footer = (props) => {
    return (
        <footer>

            <div className="content">
                <div>
                    <ContactContainer />

                    <div className="width-4 footer-menu right">
                        <img src={Logo} className="sml-logo" alt="Expensr logo" />

                        <ul>
                            <li>
                                <a href="#signup" onClick={ (e) => props.toggleSignUp(e) }>Sign up</a>
                            </li>
                            <li>
                                <a href="https://aline.digital" target="_blank" rel="noreferrer">An Aline Digital product</a>
                            </li>
                        </ul>

                        <div className="social-links">
                            <a href="#linkedin" className="social linkedin">
                                <img src={LinkedIn} alt="LinkedIn" />
                            </a>
                            <a href="#facebook" className="social facebook">
                                <img src={Facebook} alt="Facebook" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="width-12 clear footer-bottom">
                    <p>All content and design copyright &copy; Aline Digital Ltd 2024</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
