import Pencil from './../../assets/img/pencil.webp';
import Error from './error';
import ValidationError from './validation-error';

const SignUp = (props) => {

    return (
        <div className="cover">
            <div className="cover-header">
                <a href="#close" className="close" onClick={ (e) => props.toggleSignUp(e) }>&nbsp;</a>
            </div>
            <div className="cover-box">
                <div className="circled">
                    <img src={Pencil} alt="Pencil" />
                </div>

                <h2>Sign Up</h2>
                <p>We&rsquo;re currently in our final testing phases. Sign up below and we&rsquo;ll let you know when we&rsquo;re ready for you.</p>

                <Error error={props.error} />

                <form>
                    <div className="input text">
                        <label htmlFor="first">First name</label>
                        <input type="text" id="first" name="first" placeholder="First name" onChange={ (e) => props.updateField(e) } value={ props.formData.first } />
                        <ValidationError valErrs={props.valErrs} fieldName="First" />    
                    </div>

                    <div className="input text">
                        <label htmlFor="last">Last name</label>
                        <input type="text" id="last" name="last" placeholder="Last name" onChange={(e) => props.updateField(e)} value={props.formData.last} />
                        <ValidationError valErrs={props.valErrs} fieldName="Last" />
                    </div>

                    <div className="input text email">
                        <label htmlFor="email">Email address</label>
                        <input type="email" id="email" name="email" placeholder="Your email address" onChange={(e) => props.updateField(e)} value={props.formData.email} />
                        <ValidationError valErrs={props.valErrs} fieldName="Email" />
                    </div>

                    <div className="input text blind">
                        <label htmlFor="phone" className="display">Phone number</label>
                        <input type="text" id="phone" name="phone" placeholder="Phone number" onChange={(e) => props.updateField(e)} value={props.formData.phone} />
                        <ValidationError valErrs={props.valErrs} fieldName="Phone" />
                    </div>

                    <div className="input text">
                        <label htmlFor="software" className="display">What accounting / bookkeeping software do you use?</label>
                        <input type="text" id="software" name="software" placeholder="Software name" onChange={(e) => props.updateField(e)} value={props.formData.software} />
                        <ValidationError valErrs={props.valErrs} fieldName="Software" />
                    </div>

                    <button className="btn primary" onClick={ (e) => props.submitForm(e) }>{ props.btnTxt }</button>
                </form>
            </div>
        </div>
    );

}

export default SignUp;