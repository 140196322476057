

const Pricing = (props) => {
    return (
        <section id="pricing" className="white pricing">

            <div className="content">
                <div className="width-8 centred">
                    <h2>Pricing</h2>
                    <p>Whether you&rsquo;re a sole trader handling just a few expenses a month or a multi-national corporation processing thousands, we have a package to suit you. If you&rsquo;re looking for higher volumes, please <a href="#get-in-touch" onClick={ (e) => props.scrollTo(e, "contact") }>get in touch</a> using the form below.</p>
                </div>
                
                <div className="width-12 pricing">

                    <div className="price">
                        <h4>Starter</h4>
                        <ul>
                            <li>Process up to 25 invoices / user / month</li>
                            <li>Handling emailed expenses</li>
                            <li>App-based physical expense handling</li>
                            <li>Access to all accounting software integrations</li>
                        </ul>

                        <p className="the-price">&pound;<strong>2</strong>.75 <span>/ user / month</span></p>

                        <button className="btn primary" onClick={(e) => props.toggleSignUp(e)}>Get started</button>
                        <p className="sml">First 14 days free, no credit card required.</p>
                    </div>

                    <div className="price prominent">
                        <h4>Small business</h4>
                        <ul>
                            <li>Process up to 100 invoices / user / month</li>
                            <li>Handling emailed expenses</li>
                            <li>App-based physical expense handling</li>
                            <li>Access to all accounting software integrations</li>
                        </ul>

                        <p className="the-price">&pound;<strong>9</strong>.25 <span>/ user / month</span></p>

                        <button className="btn primary" onClick={(e) => props.toggleSignUp(e)}>Get started</button>
                        <p className="sml">First 14 days free, no credit card required.</p>
                    </div>

                    <div className="price">
                        <h4>Corporate</h4>
                        <ul>
                            <li>Process up to 250 invoices / user / month</li>
                            <li>Handling emailed expenses</li>
                            <li>App based physical expense handling</li>
                            <li>Access to all accounting software integrations</li>
                        </ul>

                        <p className="the-price">&pound;<strong>19</strong>.50 <span>/ user / month</span></p>

                        <button className="btn primary" onClick={ (e) => props.toggleSignUp(e) }>Get started</button>
                        <p className="sml">First 14 days free, no credit card required.</p>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default Pricing;
