import Header from "../common/header";

import IosStore from "./../../assets/img/ios-app-store.webp";
import PlayStore from "./../../assets/img/play-store.webp";
import ScreenDemo from "./../../assets/img/scan-receipt.webp";
import CurlyArrow from "./../../assets/img/curly-arrow.webp";

const LeadSlide = (props) => {
    return (
        <section className="lead-slide">
            <Header {...props} />

            <div className="content">
                <div className="width-12">

                    <div className="intro">
                        <button className="pseudo-btn pink-txt">Coming soon!</button>

                        <h1>The expense management tool that saves your team time and money</h1>
                        <h3>Remove the administrative burden of submitting expense claims from your team. Allow them to focus on what they do best.</h3>

                        <a href="#available-on" className="btn secondary left">
                            <img src={ IosStore } alt="Available on the App Store" />
                        </a>
                        <a href="#available-on" className="btn secondary left">
                            <img src={ PlayStore } alt="Get it on Google Play" />
                        </a>
                    </div>

                    <img className="illustration" src={ ScreenDemo } alt="Import receipts and invoices quickly and easily" />
                    <img className="curly-arrow" src={ CurlyArrow } alt="Curly arrow" />

                </div>
            </div>
        </section>
    );
}

export default LeadSlide;
