import EmailIcon from './../../assets/img/integrations/email.webp';
import Gmail from './../../assets/img/integrations/gmail.webp';
import Outlook from './../../assets/img/integrations/outlook.webp';
import CsvExcel from './../../assets/img/integrations/csv-excel.webp';
import Crunch from './../../assets/img/integrations/crunch.webp';
import Xero from './../../assets/img/integrations/xero.webp';
import Quickbooks from './../../assets/img/integrations/quickbooks.webp';
import Sage from './../../assets/img/integrations/sage.webp';

const Integrations = (props) => {
    return (
        <section id="integrations" className="pink integrations">

            <div className="content">
                <div className="width-8 centred">
                    <h2>Works with...</h2>
                    <p>Expensr is integrated with many of the leading cloud-based accounting packages - so it should hook directly into your existing bookkeeping processes. If your accounting platform isn&rsquo;t listed, drop us a message and we&rsquo;ll let you know when we&rsquo;ve added it.</p>
                </div>
                
                <div className="width-12 icons">

                    <div className="icon">
                        <div className="icon-logo list">
                            <img src={EmailIcon} alt="Email icon" />
                            <img src={Gmail} alt="Gmail icon" />
                            <img src={Outlook} alt="Outlook icon" className="outlook" />
                        </div>
                        <h6>Any email client</h6>
                    </div>
                    
                    <div className="icon">
                        <div className="icon-logo">
                            <img src={CsvExcel} alt="CSV / Excel icon" className="csv" />
                        </div>
                        <h6>CSV / Excel</h6>
                    </div>

                    <div className="icon">
                        <div className="icon-logo">
                            <img src={Crunch} alt="Crunch accounting logo" />
                        </div>
                        <h6>Crunch accounting</h6>
                    </div>

                    <div className="icon">
                        <div className="icon-logo">
                            <img src={Xero} alt="Xero logo" />
                        </div>
                    </div>

                    <div className="icon">
                        <div className="icon-logo">
                            <img src={Quickbooks} alt="Quickbooks logo" className="quickbooks" />
                        </div>
                    </div>

                    <div className="icon">
                        <div className="icon-logo">
                            <img src={Sage} alt="Sage logo" className="sage" />
                        </div>
                    </div>
                </div>

                <div className="width-12 centred">
                    <p className="sml">* Xero, Quickbooks and Sage coming soon. <a href="#interest" onClick={ (e) => props.toggleSignUp(e) }>Sign up</a> to be notified when they launch.</p>
                </div>
            </div>
        </section>
    );
}

export default Integrations;
