const ValidationError = (props) => {

    if (!props.valErrs) return "";

    let valErr = false;
    for (var i in props.valErrs) {
        if (props.valErrs[i].field === props.fieldName) {
            valErr = props.valErrs[i].error;
            break;
        }
    }

    if (!valErr) return "";

    return (
        <p className={ "validation-error"}>
            { valErr }
        </p>
    );

}

export default ValidationError;