import React from 'react';

import './assets/css/structure.css';
import './assets/css/core.css';

import IndexContainer from './containers/index-container';

const App = () => {
    return (
        <IndexContainer />
    );
}

export default App;
