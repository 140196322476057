import { useState } from "react";

import SignUp from "./../../components/common/sign-up";
import Fetching from "../../utilities/fetching";

const SignUpContainer = (props) => {

    const [formData, setFormData] = useState({
        first: '',
        last: '',
        email: '',
        software: '',
        phone: ''
    });
    const [error, setError] = useState(false);
    const [valErrs, setValErrs] = useState(false);
    const [btnTxt, setBtnTxt] = useState('Sign Up');
    
    // Update the entries for the form data 
    const updateField = (e) => {
        if (typeof formData[e.target.name] === 'undefined') return;
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    // Submit the form using fetch 
    const submitForm = (e) => {

        // Prevent the default action 
        e.preventDefault();

        // Reset the errors 
        setError(false);
        setValErrs(false);
        setBtnTxt('Signing Up...');

        // Submit the data
        fetch(process.env.REACT_APP_API_BASE + '/signup', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(formData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Sign Up");
            
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setError({ msg: data.msg, style: "success"});
                return;
            }
            
            // Handle errors
            setError({ msg: data.msg, style: "error" });
            setValErrs(data.detail);
            
        })
        .catch(function (error) {
            setBtnTxt("Sign Up");
            setError({ msg: "Sorry but there was an error sending your message. Please check the details you entered below and try again.", style: "error" });
            console.log('Request failed', error);
        });

    }

    return (
        <SignUp 
            formData={formData}
            updateField={updateField}
            btnTxt={btnTxt}
            submitForm={submitForm}
            error={error}
            valErrs={valErrs}
            {...props} 
        />
    );
}

export default SignUpContainer;