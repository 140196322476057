const Error = (props) => {

    if (!props.error || typeof props.error.style === 'undefined') return "";

    return (
        <div className={ "flash-msg " + props.error.style}>
            { props.error.msg }
        </div>
    );

}

export default Error;